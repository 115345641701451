<template>
  <div class="video-detail-container container_media">
    <!-- 视频 -->
    <!-- <div class="back_lastpage" @click="back">
      <img src="@/assets/image/back.png" alt="">
      <span>返回上一级</span>
    </div> -->
    <div class="crumb-wapper">
      <vf-breadcrumb :data="breadData"></vf-breadcrumb>
    </div>
    <div class="content">
      <div class="video-wrapper" v-if="videoInfo">
        <video-player
          ref="VueVideoPlayer"
          :options="playerOptions"
          :playsinline="true"
          @play="handlePLay"
        />
        <div class="video-detail-tips">
          <el-icon
            class="icon"
            name="warning-outline"
            style="vertical-align: middle"
          /><span style="vertical-align: middle">预览视频为1080P分辨率带水印版本，下载视频为去水印源文件格式</span>
        </div>
      </div>
      <!-- 视频信息 -->
      <div v-if="videoInfo" class="video-info-container">
        <div class="video-base-info">
          <div class="info-content">
            <div class="video-name">{{ dealVideoName(videoInfo.name) }}</div>
            <div class="video-keywords" v-if="keywords">
              <span><span class="title">关键词：</span>{{ keywords }}</span>
            </div>
            <div class="video-tags" v-if="labels.length">
              <span class="tag-t">标签：</span>
              <span
                class="tag"
                v-for="(i, j) in labels"
                :key="`tag-li-${j}`"
                >{{ i.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="video-operate">
          <el-button class="download-btn" type="primary"
            @click="handleDownOrFreeDown">立即下载</el-button>
          <li class="operation-item" @click="handleShare('', videoInfo.object_id, 4, videoInfo.name )">
            <img src="@/assets/svg/operation/share.svg" alt="">
          </li>
          <li class="operation-item" @click="handleVideoCollect(videoInfo.object_id, 4, videoInfo)">
            <img :src="require(`@/assets/svg/operation/${videoInfo.is_collect === 1 ? 'collected' : 'collect'}.svg`)"
              alt="">
          </li>
        </div>
      </div>
    </div>
    <VipTipDialog ref="vipTipDialog"></VipTipDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { fetchMusicInfo } from '@/api/music'
import Buttons from '@/layout/mixins/Buttons'
import VueVideoPlayer from 'vue-video-player'
import { mapGetters } from 'vuex'
import EventBus from '@/utils/bus.js'
import VipTipDialog from '@/views/limitExempt/components/VipTipDialog.vue'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

// require videojs style
import 'video.js/dist/video-js.css'
import { send_eventApi } from "@/utils/common";
Vue.use(VueVideoPlayer)

export default {
  name: 'VideoDetail',
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '视频', back: true},
        {breadcrumbName: 'name'}

      ],
      isFree: '', // 标志是否是免费专区数据
      videoInfo: null,
      videoId: null,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: '',
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  components: {VipTipDialog, VfBreadcrumb},

  mixins: [Buttons],
  computed: {
    ...mapGetters(['userInfo']),
    labels() {
      if (this.videoInfo && this.videoInfo.label) {
        return this.videoInfo.label.slice(0, 8)
      }
      return []
    },
    keywords() {
      if (this.videoInfo && this.videoInfo.label) {
        return this.videoInfo.keywords.join(',')
      }
      return []
    },
    isMusicPlaying() {
      return this.$store.state.music.isPlaying
    }
  },
  mounted() {
    const { videoId = '7300ea2e76622097001', free } = this.$route.query
    this.videoId = videoId
    this.isFree = free
    if(this.isFree) this.$set(this.breadData, 1, {breadcrumbName: '限免专区', back: true})
    else this.$set(this.breadData, 1, {breadcrumbName: '视频', path:'/video'})

    this.getVideoDetail()
  },
  // 设置下一个路由的meta,让视频列表页面缓存,即不刷新
  beforeRouteLeave(to,from,next){
    // console.log(to,from)
    if(to.path == '/search-result'){
      to.meta.keepAlive = false
    }else{
      to.meta.keepAlive = true
    }
    next()
  },
  watch: {
    isMusicPlaying(val) {
      if (val) {
        this.$refs.VueVideoPlayer.player.pause()
      }
    }
  },
  methods: {
    back(){
      this.$router.go(-1)
    },
    show(){
      this.$refs.vipTipDialog.showModal()
    },
    handleDownOrFreeDown(){
      if(this.isFree) this.handleDownLoadForFree(this.videoInfo)
      else this.handleDownload({...this.videoInfo, type: 4})
    },

    // 处理视频名称超出30个字显示……
    dealVideoName(name){
      const length = name.length
      if (length > 30) {
        var str = ''
        str = name.substring(0, 30) + '......'
        return str
      } else {
        return name
      }
    },
    handlePLay() {
      this.$store.dispatch('music/musicPause')

      const obj = {
        m_type: '视频',
        m_name: this.videoInfo.name,
        mid: this.videoInfo.object_id,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_play', obj)
    },
    /**
     * 处理视频收藏
     * 2021-12-29 18:47:41
     * @author SnowRock
     * @param object_id
     * @param mode
     * @param info
     */
    async handleVideoCollect(object_id, mode, info) {
      // info.is_collected = info.is_collect // 处理兼容
      info.is_collect = info.is_collect == 1 ? 0 : 1
      this.videoInfo.is_collect = info.is_collect
      await this.handleCollect(object_id, mode, { ...info }, false)
      // await this.getVideoDetail()
      EventBus.$emit('collect', {object_id, is_collect :info.is_collect});
    },
    /**
     * 获取视频详情
     * 2021-12-29 18:11:19
     * @author SnowRock
     * @returns {Promise<void>}
     */
    async getVideoDetail() {
      try {
        const { data } = await fetchMusicInfo({
          music_id: this.videoId
        })

        this.videoInfo = data
        this.breadData[2].breadcrumbName = data.name
        this.playerOptions.sources = [
          data.watermark_file
        ]
        this.playerOptions.poster =  data.logo
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-detail-container {
  min-height: 100vh;
  padding: 90px 0;
  .crumb-wapper{
    margin-bottom: 20px;
  }
  .video-detail-tips {
    width: 100%;
    height: 40px;
    font-size: 12px;
    color: #999;
    line-height: 40px;
    background: #FAFAFA;
    padding-left: 14px;
    .icon {
      color: #ff8e14;
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .breadcrumb {
    width: 1200px;
    /deep/ {
      .el-breadcrumb__inner {
        @include font-bold($size: 20px, $color: rgba(51, 51, 51, 0.6));
        line-height: 28px;
      }
      .el-breadcrumb__separator {
        @include font-bold(
          $size: 18px,
          $color: rgba(51, 51, 51, 0.6),
          $weight: bold
        );
      }
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    .video-wrapper {
      width: 60%;
      video {
        @include size(100%, 100%);
        object-fit: cover;
      }
    }
    .video-info-container {
      width: 40%;
      padding: 6px 0 0 40px;
      display: flex;
      flex-direction: column;
      .video-base-info {
        .info-content {
          .video-name {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
            margin-bottom: 10px;
            max-width: 30em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .video-tags {
            margin-top: 12px;
            font-size: 12px;

            .tag-t {
              margin-right: 16px;
              color: #999999;
            }
            .tag {
              height: 24px;
              text-align: center;
              padding: 0px 8px;
              color: #666;
              line-height: 24px;
              background: #FAFAFA;
              border-radius: 4px;
              & + .tag {
                margin-left: 12px;
              }
            }
          }
          .video-keywords{
            width: 100%;
            // display: flex;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #999;
            .title{
              // width: 176px;
              margin-right: 2px;
            }
          }
        }
      }
      .video-operate {
        display: flex;
        align-items: center;
        margin-top: 52px;
        .download-btn {
          flex: 1;
          height: 54px;
          background: linear-gradient(90deg, #FE7273 0%, #FC4243 100%);
          border-radius: 8px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .operation-item {
          @include square(54px);
          @include center-middle-flex;
          background: #F5F6FA;
          border-radius: 8px;
          margin-left: 12px;
          cursor: pointer;

          img {
            @include square(22px);
          }
        }
      }
    }
  }
  /deep/ {
    .vcp-container{
    }
    .video-js:before , .vcp-container:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background-image: url('~@/assets/image/video-watermark-bg.png');
      // background-position: center center;
      // background-repeat: no-repeat;
      // background-size: 500px auto;
      pointer-events: none;
      z-index: 1;
    }
    .video-js {
      position: relative;
    }
    .video-js video{
    }
    .video-js .vjs-big-play-button {
      left: 50%;
      top: 50%;
      margin: 0;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }
}
</style>
